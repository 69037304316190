import React, { useEffect, useState } from "react";
import "./carousel.css";
import { isMobile, useMobileOrientation } from 'react-device-detect';

const CarouselBaneer = ({ data }) => {
    const [slide, setSlide] = useState(0);
    const { isLandscape } = useMobileOrientation();
    const [filteredData, setFilteredData] = useState([]);
    
    useEffect(() => {
        if (isMobile && !isLandscape) {
            setFilteredData(data.filter(item => item.mobile_image));
        } else {
            setFilteredData(data);
        }
    }, [data, isMobile, isLandscape]);

    useEffect(() => {
        const interval = setInterval(() => {
            setSlide(slide === filteredData.length - 1 ? 0 : slide + 1);
        }, 2000);
        return () => clearInterval(interval);
    }, [slide, filteredData.length]);

    return (
        <div className={`${(isMobile && !isLandscape) ? "custm-carousel-mobile" : "custm-carousel"}`}>
            {filteredData.map((item, idx) => (
                <div key={idx} className={slide === idx ? "slide" : "slide slide-hidden"}>
                    <img
                        src={(isMobile && !isLandscape) ? item.mobile_image : item.image_url}
                        alt={item.title}
                        className="object-cover w-full h-full"
                        loading="lazy"
                    />
                </div>
            ))}
            <span className="indicators">
                {filteredData.map((_, idx) => (
                    <button
                        key={"circle" + idx}
                        className={`slidebutton ${idx === slide ? "active" : ""}`}
                        onClick={() => setSlide(idx)}
                        aria-label={`Slide ${idx + 1}`}
                    />
                ))}
            </span>
        </div>
    );
};

export default CarouselBaneer;
