import { useEffect, useState } from "react";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { Typography } from "@mui/material";
import { getProduct, getProductBanner } from "../../../services/index";
import { notificationService } from "../../../services/notifications/notificationService";
import Popup from "../../private/Product/popup";
import { useDispatch, useSelector } from "react-redux";
import { addItemsToCart, deleteCartItem } from "../../../services/checkout";
import { fetchCartList } from "../../public/Home";
import { addToCart, removeFromCart } from "../../../reducers/cart/actions";
import "./Product.css";
import NoDataFound from "../../../services/utils/noDataPopUp";
import ApplicationHelmet, { navigateToNext } from "../../../navigation/navigation";
import images from "../../../images/images";
import ProductBaneer from "../../../components/carousel/productcarousel";
import { SpinLoader } from "../../../app/loader/loader";

const Product = () => {
  const { id } = useParams();
  const location =  useLocation();
  const isSubCategoryBlank =  location.state?.subCategory;
  const userId = localStorage.getItem('userId');
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [auth, setAuth] = useState(false);
  const [productList, setProductList] = useState({});
  const [showAll, setShowAll] = useState(false);
  const [showProduct, setShowProduct] = useState([]);
  const [currentSub, setCurrentSub] = useState(id);
  const [currentSubName, setCurrentSubName] = useState("All");
  const [addProduct, setAddProduct] = useState(null);
  const dispatch = useDispatch();
  const cartStore = useSelector((state) => state.cartReducer.cart);
  const navigate = useNavigate();
  const [productbannerList, setproductBannerList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const setSearchHandler = (subid) => {
    const payload = {
      keyword: "",
      subcategory_id: subid,
      min_price: "",
      max_price: "",
      net_wt_unit: "",
    };
    const payloadsdfghj = {
      "position": id,
      "subcategory_id": subid,
    };
    setCurrentSub(subid);
    getProductsList(payload);
    getProductBanners(payloadsdfghj);
  };
  
  useEffect(() => {

    if (token) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  }, [token]);

  const getProductBanners = (category) => {
    getProductBanner(category)
      .then((response) => {
        setLoading(false)
        if (response.success && response.receiveObj) {
          const data = response?.receiveObj?.data;
          setproductBannerList(data);
        }
      })
      .catch((error) => {
        setproductBannerList([]);
        notificationService.sendMessage({
          type: "error",
          title: "Banner List",
          text: error.receiveObj.message,
        });
      });
  };

  const getProductsList = (payload) => {
    getProduct(payload)
      .then((response) => {
        if (response.success && response.receiveObj) {
          const data = response.receiveObj;
          const orgData = data.products;
          if (orgData.length < 15) {
            setShowProduct(orgData);
            setShowAll(false);
          } else {
            const slicedArray = orgData.slice(0, 15);
            setShowProduct(slicedArray);
            setShowAll(true);
          }
          setProductList({ ...data, products: orgData });
        }
      })
      .catch((error) => {
        setProductList([]);
        notificationService.sendMessage({
          type: "error",
          title: "Product List",
          text: error.receiveObj.message,
        });
      });
  };


  const handleAddToCart = (product) => {
    const productInCart = cartStore?.find((item) => item.id === product.id);
    const {id, variant_id, texture_id} = product;
    if (productInCart) {
      if (auth) {
        deleteCartItem({productId: userId, variantId: variant_id, textureId: texture_id}).then(() => {
          fetchCartList(dispatch);
        });
      }
      dispatch(removeFromCart(id, product?.variant_id, texture_id));
    } else {
      if (auth) {
        addItemsToCart({ product_id: product.id, quantity: 1, variant_id: product?.variant_id, texture_id: product?.texture_id }).then(() => {
          fetchCartList(dispatch);
        });
      }
      dispatch(addToCart({...product, quantity: 1}));
      setAddProduct({ ...product, quantity: 1, variant_id: product?.variant_id, texture_id: product?.texture_id });
      setTimeout(() => {
        setAddProduct(null);
      }, 2000);
    }
  };

  const showImgHandler = () => {
    setShowProduct(productList?.products);
    setShowAll(false);
  };

  const handleNavigate = (e, cat, subCat, id) => {
    const newCategory = encodeURIComponent(cat);
    const newSubCategory = encodeURIComponent(subCat);
    if (e.ctrlKey || e.metaKey) {
      window.open(
        window.location.origin +
        `/productdetails/${newCategory}/${newSubCategory}/${id}`,
        "_blank"
      );
    } else {
      navigate(`/productdetails/${newCategory}/${newSubCategory}/${id}`);
    }
  };

  useEffect(() => {
    setLoading(true)
    const payload = {
      "keyword": "",
      "subcategory_id": "",
      "min_price": "",
      "max_price": "",
      "net_wt_unit": "",
    };
    const category = {
      "position": id,
      "subcategory_id": "",
    }
    getProductsList(payload);
    setCurrentSub("");
    getProductBanners(category);
    if (currentSubName === "All") {
      getProductBanners(category)
    }
  }, [id, isSubCategoryBlank]);


  return (
    <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 mt-28 mb-10">
      <ApplicationHelmet/>
      {addProduct && <Popup product={addProduct} />}
      <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 ">
        {isLoading && <div className="mt-20"><SpinLoader height="55px" width="55px" /></div>}
        {productbannerList?.category && id && (
          <div className="xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12">
            <ProductBaneer data={productbannerList?.category} />
          </div>
        )}
      </div>
      <div className={`lg:col-span-12 md:col-span-12 sm:col-span-12 p-2 category-title ${productbannerList?.category?.description ? '' : 'category-title-space'}`}>
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{ __html: productbannerList?.category?.description }}
          className="dynamin-contents"
          sx={{ fontFamily: 'Greycliff CF', margin: '0 auto' }}
        />
      </div>
      <div className="lg:col-span-2 md:col-span-2 sm:col-span-3 xs:col-span-6 p-2 ml-3 productcatagoriesbox">
        <div className="text-sm font-articulat font-normal cursor-pointer mb-2 mt-3 product-content-text">
          <span onClick={(e) => navigateToNext(e, "/home", navigate)}>
            Home &nbsp; / &nbsp;{" "}
          </span>
          <span>{productbannerList?.category?.name} &nbsp; / &nbsp; </span>
          <span className="text-[#005956]">{currentSubName}</span>
        </div>
        <span className="text-base font-articulat font-semibold mt-2 cursor-pointer">
          Categories
        </span>
        <br />
        <div
          className={
            "text-sm font-articulat font-medium mt-3 cursor-pointer" +
            (currentSub === 0 ? " text-[#005956]" : "")
          }
          onClick={() => {
            setCurrentSubName("All");
            setSearchHandler("");
          }}
        >
          All
        </div>
        {productbannerList?.subcategories?.map((ele, index) => (
          <div
            className={
              "text-sm font-articulat font-medium mt-2 cursor-pointer" +
              (currentSub === ele.id ? " text-[#005956]" : "")
            }
            onClick={() => {
              setCurrentSubName(ele.name);
              setSearchHandler(ele.id);
            }}
            key={index}
          >
            {ele.name}
          </div>
        ))}
      </div>
      <div className="lg:col-span-0 md:col-span-0 sm:col-span-0 xs:col-span-6 p-2 ml-3 productcatagoriesbox-1">
        <span className="text-base font-articulat font-semibold mt-2 cursor-pointer">
          Categories
        </span>
        <select
          value={currentSub}
          onChange={(e) => {
            const selectedSubId = e.target.value;
            const selectedSub = productList.subcategories.find(
              (sub) => sub.id === selectedSubId
            );
            setCurrentSubName(selectedSub?.name);
            setSearchHandler(selectedSubId);
            if (selectedSubId === "0") {
              setCurrentSubName("All");
              setSearchHandler("");
            }
          }}
          className="text-sm bg-white productcatagorieselect"
        >
          <option value={0}>All</option>
          {productbannerList?.subcategories?.map((ele) => (
            <option key={ele.id} value={ele.id}>
              {ele.name}
            </option>
          ))}
        </select>
      </div>

      <div className="lg:col-span-10 md:col-span-10 sm:col-span-9 xs:col-span-12 p-2 ml-3 productcardbox">
        <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12">
          {productbannerList?.products?.length !== 0 ? (
            productbannerList?.products?.map((ele, i) => {
              let prodId = ele.id;
              let cartShow = cartStore?.find((item) => item.id === ele.id);
              const productUrl = `/productdetails/${encodeURIComponent(ele?.category?.name)}/${encodeURIComponent(ele?.subcategory?.name)}/${ele.id}`;
              if (prodId === 42 && userId != 184) return null;
              return (
               <div className="lg:col-span-4 md:col-span-6 sm:col-span-12 m-3" key={i}>
                  <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 rounded-xl inset-0 flex">
                  <Link to={productUrl}>
                    <img
                      src={ele.attachment_url[0]}
                      alt="productImage"
                      loading="lazy"
                      className="2xl:w-full 2xl:h-full sm:w-[350px] sm:h-[280px] m-auto rounded-lg cursor-pointer"
                    />
                    </Link>
                  </div>
                  <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-base font-articulat font-normal mt-2 sm:h-10 xxs:h-[66px] cursor-pointer hover:text-[#268126]">
                   <Link to={productUrl}>{ele.name}</Link>
                  </div>
                  <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 divide-x cursor-pointer bg-white">
                    <div className="lg:col-span-4 md:col-span-4 sm:col-span-4 text-left">
                      <span className="text-[#333333] text-xs font-articulat font-normal">
                        Net wt. :
                      </span>
                      <span className=" text-xs font-articulat font-semibold">
                        {ele.net_wt + ele.unit}
                      </span>
                    </div>
                    <div className="lg:col-span-8 md:col-span-8 sm:col-span-8">
                      <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                        <div className="lg:col-span-9 md:col-span-9 sm:col-span-9 text-right" style={{ marginRight: "15px" }}>
                          <span className="text-[#667479] text-xs font-articulat font-normal">
                            MRP :
                          </span>
                          <span className=" text-xs font-articulat font-semibold">
                            ₹{(ele.cost_price !== null && parseFloat(ele.cost_price) !== 0.0) ? ele.cost_price : ele.master_price}
                          </span>
                          {ele.master_price && <span className="text-[#333333] text-xs font-articulat font-light line-through">
                            {(ele.cost_price !== null && parseFloat(ele.cost_price) !== 0.0) ? "₹" + ele.master_price : ''}
                          </span>}
                        </div>
                        <Tooltip
                          anchorSelect=".cart"
                          place="left"
                          style={{ backgroundColor: "#FBF5F1", color: "#222" }}
                        >
                          Add to cart
                        </Tooltip>
                        <div className="text-right lg:col-span-3 md:col-span-3 sm:col-span-3">
                          <span style={{ textAlign: '-webkit-right' }}>
                            <img className="cursor-pointer" alt="cartItemImage" loading="lazy" src={cartShow ? images.cartActive : images.cartInactive} onClick={() => handleAddToCart(ele)} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>

      <div className="productcardbox-1">
        {productbannerList?.products?.length !== 0 ? (
          productbannerList?.products?.map((ele, i) => {
            let cartShow = cartStore?.find((item) => item.id === ele.id);
            let prodId = ele.id;
            const productUrl = `/productdetails/${encodeURIComponent(ele?.category?.name)}/${encodeURIComponent(ele?.subcategory?.name)}/${ele.id}`;
            if (prodId === 42 && userId != 184)  return null;
            return (
            <div className="" key={ele?.id}>
                <div className="productcardsubdiv">
                  <Link to={productUrl}>
                  <img
                    src={ele.attachment_url[0]}
                    alt="productImage"
                    loading="lazy"
                    className="w-full m-auto rounded-lg cursor-pointer product-res-img"
                  />
                  </Link>
                  <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-base font-articulat font-normal mt-2 sm:h-10 xxs:h-[66px] cursor-pointer hover:text-[#268126]">
                    <Link to={productUrl}>
                      {ele.name}
                    </Link>
                  </div>
                  <div className="productcardpricebox bg-white">
                    <div>
                      <div>
                        <span className="text-[#333333] text-xs font-articulat font-normal">
                          Net wt. :
                        </span>
                        <span className=" text-xs font-articulat font-semibold">
                          {ele.net_wt + ele.unit}
                        </span>
                      </div>
                      <div>
                        <span className="text-[#667479] text-xs font-articulat font-normal">
                          MRP :
                        </span>
                        <span className=" text-xs font-articulat font-semibold">
                          ₹{(ele.cost_price !== null && parseFloat(ele.cost_price) !== 0) ? ele.cost_price : ele.master_price}
                        </span>
                        {ele.master_price && <span className="text-[#333333] text-xs font-articulat font-light line-through"> 
                          {(ele.cost_price !== null && parseFloat(ele.cost_price) !== 0) ? "₹" + ele.master_price : ''}
                        </span>}
                      </div>
                    </div>
                    <div>
                      <Tooltip
                        anchorSelect=".cart"
                        place="left"
                        style={{ backgroundColor: "#FBF5F1", color: "#222" }}
                      >
                        Add to cart
                      </Tooltip>
                      <div className="text-right lg:col-span-3 md:col-span-3 sm:col-span-3">
                        <span>
                          <img className="cursor-pointer" alt="cartImage"  loading="lazy" src={cartShow ? images.cartActive : images.cartInactive} onClick={() => handleAddToCart(ele)} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
            );
          })
        ) : (
          <NoDataFound />
        )}
      </div>
      {/* {showAll && (
        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-center mb-5">
          <button
            className="focus:outline-none text-white buttoncommon hover:bg-[#009898] mt-5 focus:ring-4 focus:ring-[#005956] font-medium rounded-lg text-sm px-5 py-2.5 p-3"
            onClick={() => showImgHandler(showAll)}
          >
            Show More
          </button>
        </div>
      )} */}
    </div>
  );
};

export default Product;
